<template>
  <div>
    <!-- pic90746993BA7626D1AFD23776C3EC3849.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4.jpg">

    <p>
      Welcome to part 4 of this special series on building the best creations in LEGO Universe! Lead world artist Nathan Storm returns in this edition to illuminate some of the steps his team takes to make lustrous looking electronic models!
    </p>
    <p>
      Nathan says the lighting in typical digital gameworlds is pretty unflattering for our favorite bricks. For example, if you were building a LEGO model outside on a sunny afternoon, every brick, tube and stud would stand out.
    </p>
    <p>
      If a cloud rolled in front of the sun, though, you would be looking at your creation in “ambient” light that would make it look less detailed. Electronic game engines mostly mimic dim ambient light, which can leave models like this one looking downright flat.
    </p>

    <!-- picCB9A61457488A580F6C8491A36827272.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4-1.jpg">

    <p>
      Luckily, LEGO Universe artists are working hard to create deluxe digital models! Nathan and his team of world artists have developed a three-part “model enhancement” program to get our beloved LEGO bricks looking their best:
    </p>
    <p>
      <b>Brick color variation </b>- Making some of a digital model’s bricks slightly darker or lighter helps each piece to stand out. See how the additional shade really distinguishes the LEGO brick rows in this sample dojo’s roof?
    </p>

    <!-- pic02915A1474FD7034ED0CCCE6DBD86B25.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4-2.jpg">

    <p>
      <b>Ambient occlusion </b>- This mouthful helps make up for some of the flattening effect of ambient light. Basically, ambient occlusion increases variation between the lights and shadows, which makes LEGO Universe models look much more like they’re in natural light!
    </p>

    <!-- picD717D1B316FA69C5B06B2D4AC3CBAF7C.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4-3.jpg">

    <p>
      <b>Light baking </b>- 3D programs generally handle lighting better than game engines, so LEGO Universe world artists use special software to light their models before placing them in the gameworld.
    </p>

    <!-- pic2CAB1EEBB5663BE95939ADA4A34F3EA6.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4-4.jpg">

    <p>
      LEGO Universe world artists are wielding a lot of technical know-how to help build LEGO models that look right at home in a lush and richly textured gameworld!
    </p>

    <!-- pic82C7B743A5F413224497CB8B9037A9F6.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/create-digital-designs-the-lu-way-4-5.jpg">

    <p>
      Coming up in part 5, lead animator Erik Beyer will start showing us how his team makes LEGO Universe come alive!
    </p>
    <p>
      This edition’s LEGO Universe building tips:
    </p>
    <p>
      - Think of limitations as a way to push your creativity!
    </p>
    <p>
      - Never compromise on the quality of your creation!
    </p>
    <p>
      Follow these links if you missed earlier editions of this special series!<br>
      <router-link :to="{ name: 'story', params: {id: 82873} }">
        Creating digital designs the LEGO Universe way: Part 1
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 88803} }">
        Creating digital designs the LEGO Universe way: Part 2
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 91070} }">
        Creating digital designs the LEGO Universe way: Part 3
      </router-link>
    </p>
  </div>
</template>
